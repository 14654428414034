/* eslint-disable prettier/prettier */
<template>
  <div class="student-dashboard">
    <div class="container-fluid p-0">
      <div class="row g-0 _content-row">
        <div class="col-md-3">
          <div class="sidebar-wrapper">
            <div class="_menu-wrapper">
              <ul class="list-unstyled">
                <li>
                  <router-link
                    to="#"
                    @click="updateApp('all')"
                    :class="activeApp == 'all' ? 'active' : ''"
                  >
                    <img src="../assets/discuss-icon.svg" alt="" />
                    All</router-link
                  >
                </li>
                <li v-if="isPupaMember == true">
                  <router-link
                    to="#"
                    @click="updateApp('pupa')"
                    :class="activeApp == 'pupa' ? 'active' : ''"
                  >
                    <img src="../assets/class-icon.svg" alt="" />
                    Pupa</router-link
                  >
                </li>
                <li v-if="isNestMember == true">
                  <router-link
                    @click="updateApp('nest')"
                    to="#"
                    :class="activeApp == 'nest' ? 'active' : ''"
                  >
                    <img src="../assets/webinar-icon.svg" alt="" />
                    Nest</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="poster-box">
              <img src="../assets/poster1.png" alt="" />
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="topmenu-wrapper">
            <ul class="list-unstyled" v-if="activeApp == 'all'">
              <li>
                <router-link
                  :to="{ name: 'dashboardTab' }"
                  :class="activeTab == 'dashboardTab' ? 'active' : ''"
                >
                  <!-- <img src="../assets/profile-icon.svg" alt="" /> -->
                  Dashboard</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'profile' }"
                  :class="activeTab == 'profile' ? 'active' : ''"
                >
                  <!-- <img src="../assets/profile-icon.svg" alt="" /> -->
                  Profile</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'certificate' }"
                  :class="activeTab == 'certificate' ? 'active' : ''"
                >
                  <!-- <img src="../assets/profile-icon.svg" alt="" /> -->
                  Certificate</router-link
                >
              </li>
            </ul>
            <ul class="list-unstyled" v-if="activeApp == 'pupa'">
              <li>
                <router-link
                  :to="{ name: 'pupaDashboardTab' }"
                  :class="activeTab == 'pupaDashboardTab' ? 'active' : ''"
                >
                  <!-- <img src="../assets/board-icon.svg" alt="" /> -->
                  Dashboard</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'schedules' }"
                  :class="activeTab == 'schedules' ? 'active' : ''"
                >
                  <!-- <img src="../assets/class-icon.svg" alt="" /> -->
                  Classes</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'events' }"
                  :class="activeTab == 'events' ? 'active' : ''"
                >
                  <!-- <img src="../assets/webinar-icon.svg" alt="" /> -->
                  Events</router-link
                >
              </li>

              <li>
                <router-link
                  :to="{ name: 'discussions' }"
                  :class="
                    activeTab == 'discussionTab' || activeTab == 'discussions'
                      ? 'active'
                      : ''
                  "
                >
                  <!-- <img src="../assets/discuss-icon.svg" alt="" /> -->
                  Discussion</router-link
                >
              </li>
              <li v-if="isShowStudyMaterials == true">
                <router-link
                  :to="{
                    name: 'studyMaterials',
                    params: { slug: program.slug }
                  }"
                  :class="activeTab == 'studyMaterials' ? 'active' : ''"
                >
                  <!-- <img src="../assets/class-icon.svg" alt="" /> -->
                  Study Materials</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'academics' }"
                  :class="activeTab == 'academics' ? 'active' : ''"
                >
                  <!-- <img src="../assets/report-icon.svg" alt="" /> -->
                  Academics</router-link
                >
              </li>
            </ul>
            <ul class="list-unstyled" v-if="activeApp == 'nest'">
              <li>
                <router-link
                  :to="{ name: 'nestEvents' }"
                  :class="activeTab == 'nestEvents' ? 'active' : ''"
                >
                  <!-- <img src="../assets/profile-icon.svg" alt="" /> -->
                  Events</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'nestDiscussions' }"
                  :class="activeTab == 'nestDiscussions' ? 'active' : ''"
                >
                  <!-- <img src="../assets/profile-icon.svg" alt="" /> -->
                  Discussion</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'nestQuizzes' }"
                  :class="activeTab == 'nestQuizzes' ? 'active' : ''"
                >
                  <!-- <img src="../assets/profile-icon.svg" alt="" /> -->
                  Quiz</router-link
                >
              </li>
            </ul>
          </div>
          <div class="body-wrapper">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramService from "@/services/ProgramService";
import NestScheduleService from "@/services/NestScheduleService";
import UserService from "@/services/UserService";
import errorLog from "@/errorLog";

export default {
  name: "StudentDashboard",
  components: {},
  computed: {
    activeTab() {
      return this.$route.name;
    },
    activeApp() {
      if (this.allMenu.includes(this.$route.name)) {
        return "all";
      } else if (this.pupaMenu.includes(this.$route.name)) {
        return "pupa";
      } else if (this.nestMenu.includes(this.$route.name)) {
        return "nest";
      } else {
        return "all";
      }
    }
  },
  data() {
    return {
      detailDiscussionId: "",
      examId: "",
      slug: this.$route.params.slug,
      assignment: [],
      program: [],
      isShowStudyMaterials: false,
      isNestMember: false,
      isPupaMember: false,
      allMenu: ["profile", "dashboardTab", "certificate"],
      pupaMenu: [
        "schedules",
        "events",
        "studyMaterials",
        "pupaDashboardTab",
        "discussions",
        "discussionReply",
        "academics",
        "assignments",
        "exam",
        "examResult",
        "recordings",
        "recording"
      ],
      nestMenu: [
        "nestEvents",
        "nestRecordings",
        "nestDiscussions",
        "nestQuizzes",
        "nestQuiz",
        "quizResult"
      ]
    };
  },
  created() {
    if (this.$route.query.from == "zoom-error") {
      var currentUrl = window.location.href;
      var dashboardUrl = currentUrl.split("?")[0];
      window.location.replace(dashboardUrl);
    }
    if (this.$route.query.from == "zoom-success") {
      this.completeMeeting();
    }
    if (this.$route.query.from == "event-success") {
      this.completeEvent();
    }
    this.getActivePrograms();
    this.getPrivilege();
  },
  methods: {
    completeMeeting: function() {
      ProgramService.completeMeeting({
        schedule_id: this.$route.query.schedule
      })
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          this.examLoader = false;
          errorLog.log(error);
        });
    },
    completeEvent: function() {
      NestScheduleService.completeEvent({
        event_id: this.$route.query.schedule
      })
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          this.examLoader = false;
          errorLog.log(error);
        });
    },

    async getActivePrograms() {
      await ProgramService.getActiveProgram()
        .then(result => {
          if (result.data.status == "success") {
            this.program = result.data.program;
            this.isShowStudyMaterials = true;
          }
        })
        .catch(error => {
          this.isPaymentProgressing = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },

    updateApp: function(app) {
      if (app == "all") {
        this.$router.push({ name: "dashboardTab" });
      } else if (app == "pupa") {
        this.$router.push({ name: "pupaDashboardTab" });
      } else if (app == "nest") {
        this.$router.push({ name: "nestEvents" });
      }
    },

    getPrivilege() {
      UserService.getPrivilege().then(result => {
        this.$store.dispatch("privilege/pupaMember", result.data.pupa_member);
        this.$store.dispatch("privilege/nestMember", result.data.nest_member);
        this.isNestMember = result.data.nest_member;
        this.isPupaMember = result.data.pupa_member;
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/student-dashboard.scss";
</style>
